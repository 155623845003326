import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  constructor(private http: HttpClient) {}

  get apiUrl(): string {
    // return 'http://localhost:5800/dataservice';
    return localStorage.getItem('api-url');
  }
  generateDocument(body) {
    return this.http.post(`${this.apiUrl}/document/render`, body, {
      responseType: 'text',
    });
  }

  getDocuments(companyId, pensionFundId, foundationId, getReviewDocuments, offerId) {
    let params;

    if (getReviewDocuments) {
      params = {
        companyId: companyId,
        pensionFundId: pensionFundId,
        foundationId: foundationId,
        reviewDocuments: getReviewDocuments,
        offerId: offerId,
      };
    } else {
      params = {
        companyId: companyId,
        pensionFundId: pensionFundId,
        foundationId: foundationId,
        offerId: offerId,
      };
    }

    return this.http.get(`${this.apiUrl}/document/offerDocuments`, {
      params: params,
    });
  }

  getDocument(uuid) {
    return this.http.get(`${this.apiUrl}/document/offerDocuments/${uuid}`, {
      responseType: 'arraybuffer',
    });
  }

  deleteDocuments(uuids) {
    return this.http.post(`${this.apiUrl}/document/offerDocuments/batch`, uuids);
  }

  getOffersByStatus(year) {
      return this.http.get<any>(`${this.apiUrl}/offertool/report/offers/statuses?year=${year}`);
  }

  getOffersByAge(year) {
      return this.http.get<any>(`${this.apiUrl}/offertool/report/offers/age?year=${year}&client=${environment.client}`);
  }

  getOffersByPensionSavings(year) {
      return this.http.get<any>(`${this.apiUrl}/offertool/report/offers/summaries?year=${year}&client=${environment.client}`);
  }

  getOffersKeyFigures(year, page, size, sortDirection, sortColumn) {
      return this.http.get<any>(`${this.apiUrl}/offertool/report/offers/summaries/table?year=${year}&size=${size}&page=${page}&sortDirection=${sortDirection}&sortColumn=${sortColumn}`);
  }

  getOffersKeyFiguresTotals(year) {
    return this.http.get<any>(`${this.apiUrl}/offertool/report/offers/summaries/totals?year=${year}`);
}
}
